export const conversionTrackingMixin = {
    methods: {
        loadScript(options) {
            new Promise((resolve, reject) => {
                const script = document.createElement('script');
                for (const key in options) {
                    script[key] = options[key]
                }
                script.onload = () => resolve(script);
                script.onerror = () => reject(script);
                document.head.append(script)
            })
        },
        setConversionScripts(text) {
            const div = document.createElement('div');
            div.innerHTML = text;
            const childNodes = [...div.childNodes];

            for (let i = 0; i < childNodes.length; ++i) {
                const el = childNodes[i];

                if (el.nodeName.toLowerCase() === 'script' && el.attributes.src) {
                    const src = el.attributes.src;

                    this.loadScript({src: src.value})

                } else {
                    document.head.append(el);

                    if (el.nodeName.toLowerCase() === 'script') {
                        const code = el.innerText;
                        try {
                            eval(code);
                        } catch (error) {
                            console.error(error);
                        }

                    }
                }
            }
        }
    }
}

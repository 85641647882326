export const FONT_NAMES = {
    OPEN_SANS: 'Open Sans',
    ROBOTO: 'Roboto',
    LATO: 'Lato',
    AVENIR_NEXT_LPRO_REGULAR: 'AvenirNextLTPro-Regular'
};
export const VIEW_REPORT = {
    FULL_REPORT: 0,
    DONT_DISPLAY: 1,
    FIRST_PAGE_ONLY: 2

};
export const FORMAT_TYPES = {
    EMBED: 'embed',
    POPUP: 'popup',
    LANDING_PAGE: 'landing'

};
export const SUBSCRIPTION_STATUS = {
    VALID: "Valid",
    CANCELLED: "Cancelled",
    EXPIRED: "Expired"
};
export const SUBSCRIPTION_INTERVAL = {
    MONTHLY: 'MONTHLY',
    YEARLY: 'YEARLY'
};
export const PLAN_TYPE = {
    FREE_TRIAL: 'FREE_TRIAL',
    PRO: 'PRO'
};

export const LEAD_TYPES = {
    WEB: 'WEB',
    LANDING_PAGE: 'LANDING_PAGE',
    EMBED: 'EMBED',
    APP: 'APP',
    WORDPRESS: 'WORDPRESS',
    CHROME: 'CHROME',
    FORM: 'FORM',
    BULKUPLOAD: 'BULKUPLOAD',
};

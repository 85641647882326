export const SHORT = {
    LargeContentfulPaint: {
        GOOD: 'Your page’s Large Contentful Paint (LCP) is less than 2.5',
        BAD: 'Your page’s Large Contentful Paint (LCP) is more than 2.5'
    },
    CumulativeLayoutShift: {
        GOOD: 'Your page’s Cumulative Layout Shift is less than 0.1',
        BAD: 'Your page’s Cumulative Layout Shift is more than 0.1'
    },
    TitleTag: {
        GOOD: ' Your page title looks good.',
        AVERAGE: 'Your page title needs a relook. Best Practise : 70 Characters.',
        BAD: ' Your page does not have page title at all.'
    },
    DescriptionTag: {
        GOOD: 'Your meta description looks good.',
        BAD: 'Your page does not have description tag. ',
        AVERAGE: 'Your meta description needs a relook. Best Practise: 320 Characters.'
    },
    ImageAltText: {
        GOOD: 'Your site does not have any image without alt text.',
        BAD: 'Total Number of Images with out Alt Text Mentioned: :100',
    },
    ImageAnalyses: {
        GOOD: 'Properly size images.',
        BAD: 'Properly size images.',
    },
    DeferOffscreenImages: {
        GOOD: 'Offscreen images are deferred.',
        BAD: 'Offscreen images are loaded.',
    },
    EfficientlyEncodeImages: {
        GOOD: 'Efficiently encode images.',
        BAD: 'Efficiently encode images.',
    },
    InternalLinks: {
        GOOD: 'This page has :100 internal links.',
        BAD: 'This page has :100 internal links.',
    },
    ExternalLinks: {
        GOOD: 'This page has :100 outbound links.',
        BAD: 'This page has :100 outbound links.',
    },
    SEOFriendly: {
        GOOD: 'Your links are SEO friendly.',
        BAD: 'Some links of your site are not SEO friendly.',
    },
    RobotTxt: {
        GOOD: 'Your page has robots.txt.',
        BAD: 'Your page does not have robots.txt.',
    },
    SiteMap: {
        GOOD: 'Your site has a sitemap.',
        BAD: 'We detected that there is no sitemap specified on the webpage.',
    },
    DeprecatedHTMLTag: {
        GOOD: 'Your site does not have any deprecated HTML tags.',
        BAD: 'Total Number of Deprecated HTML Tags: :100',
    },
    HtmlPageSize: {
        GOOD: 'HTML Page size is :100 KB (<100 KB)',
        BAD: 'We detected that the time required to render the page high.HTML page size is > 100KB ',
    },
    GzipCompression: {
        GOOD: 'Compression is enabled and is :100 KB (<33KB)',
        BAD: 'We detected that resources were not compressed using gzip compression.',
    },
    InlineCSS: {
        GOOD: 'Your site does not have any inline css.',
        BAD: 'Your site have :100 inline CSS occurance ',
    },
    InternalCSS: {
        GOOD: 'Your site does not have any internal css.',
        BAD: 'Your site have :100 internal CSS occurance',
    },
    MicroDataSchemaTest: {
        GOOD: 'Website passed Micro Data Schema Test.',
        BAD: 'Website failed Micro Data Schema Test.',
    },
    IPCanonicalizationTest: {
        GOOD: 'Website passed IP Canonicalization Test. ',
        BAD: 'Website failed IP Canonicalization Test.',
    },
    TextHtmlRatio: {
        GOOD: 'Website passed text/HTML ratio test.',
        BAD: 'Text to HTML ratio is too low (<20%)',
    },
    TotalWordsCount: {
        GOOD: 'Total words on the page is :100, you need to have more than 400 words.',
        BAD: 'Total words on the page is :100, you need to have more than 400 words.',
    }

};
export const RATE = {
    BAD: 'BAD',
    GOOD: 'GOOD',
    AVERAGE: 'AVERAGE'
};



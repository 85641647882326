<template>
	<div class="header_pdf">

		<div class="sf-cnt-header">
			<div class="sf-cnt-header__logo"><img :src="logo_path" alt=""></div>
			<div class="sf-cnt-header__contact">
				<p>{{preparedBy}}: <a>{{name}}</a></p>
				<p><a>{{phone}}</a></p>
				<p><a class="blue-link">{{email}}</a></p>
			</div>
		</div>
		<div class="sf-cnt-hero__info-progress">
			<div class="progress-circle" :id="scoreBarHeaderID">

			</div>
			<div class="d-flex justify-content-center flex-column ml-20">
				<div class="sf-cnt-hero__info-txt header_for_pdf">{{webpage}}:
					<a class="green-link">{{url}}</a>
				</div>
				<div class="sf-cnt-hero__info-txt header_for_pdf">{{overAllScore}}:
					<a class="green-link">{{score}}/100</a>
				</div>
			</div>
		</div>

	</div>
</template>

<script>
    import * as ProgressBar from "progressbar.js";

    export default {
        name: "HeaderPdf",
        props: {
            webpage: {
                type: String
            },
            overAllScore: {
                type: String
            },
            preparedBy: {
                type: String
            },
            scoreBarHeaderID: {
                type: String
            },
            name: {
                type: String
            },
            phone: {
                type: String
            },
            email: {
                type: String
            },
            logo_path: {
                type: String
            },
            score: {
                type: String
            },
            url: {
                type: String
            },
        },
        mounted() {
            let that = this;
            let id = '#' + this.scoreBarHeaderID;
            let bar = new ProgressBar.Circle(id, {
                strokeWidth: 18,
                easing: 'easeInOut',
                duration: 1400,
                color: 'rgb(80, 213, 133)',
                trailColor: '#eee',
                trailWidth: 9,
                svgStyle: null,

                text: {
                    // Initial value for text.
                    // Default: null
                    value: that.score + '<span>/100</span>',

                    // Class name for text element.
                    // Default: 'progressbar-text'
                    className: 'progress-value',
                    style: {
                        position: 'absolute',
                        left: '50%',
                        top: '50%',
                        padding: '0px',
                        margin: '0px',
                        transform: 'translate(-50%, -50%)',
                        color: '#15394f'
                    }
                },

            });

            bar.set(this.score / 100);  // Number from 0.0 to 1.0

        }
    }
</script>

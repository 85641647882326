<template>
	<div>
		<loading :active.sync="isPdfLoading"
				 :is-full-page='true'
				 color="#9ba0a5"
				 width="64"
				 height="64"
				 :enforce-focus="false"
				 :lock-scroll="true"/>

		<div v-if="dataLoading || !settingsLoaded">
			<PreparationPopup></PreparationPopup>
		</div>
		<div v-if="error">
			<div class="container">
				<div class="mt-4 alert alert-secondary">{{error}}</div>
			</div>
		</div>

		<div v-if="!error && !dataLoading && settingsLoaded" class="format-report-next">
			<div class="container">
				<div class="fr-btns">
					<b-dropdown size="lg" variant="link" toggle-class="btn btn-light fr-btn text-decoration-none" ref="pdfDropdown"
								no-caret>
						<template #button-content>
							<i class="icon-export"></i> Export
						</template>
						<div class="dropdown-export">
							<p>Export</p>
							<a class="dropdown-export__download" @click="generatePDF">
								<i class="icon-export"></i> PDF
							</a>
						</div>

					</b-dropdown>

					<b-dropdown size="lg" variant="link" toggle-class="btn btn-light fr-btn text-decoration-none"
								no-caret>
						<template #button-content>
							<i class="icon-share"></i> Share
						</template>
						<div class="dropdown-export">
							<p>Share</p>
							<div class="form-group">
								<input class="form-control" type="text" disabled :value="currentUrl">
								<a class="btn btn-primary"
								   v-clipboard="() => currentUrl"
								   @success="handleSuccess"
								   @error="handleError">Copy</a>
							</div>
						</div>

					</b-dropdown>

				</div>
				<div class="fr-page">
					<div class="sf-main">
						<div class="sf-cnt">
							<div class="sf-cnt-main sf-cnt-main--page" :class="{'pdf-container': isPdf}">
								<ReportContent :settings="reportSettings" :data="report" :isPdf="isPdf"
											   @refresh="refreshReport">
								</ReportContent>

							</div>
						</div>
					</div>
				</div>
			</div>
		</div>


	</div>
</template>

<script>
    import PreparationPopup from "./PreparationPopup";
    import {FONT_NAMES, VIEW_REPORT} from "../../../../shared/constants";
    import {SHORT, RATE} from "../../../../shared/constants/recommendations";
    import ReportContent from "../../../../shared/components/ReportContent";
    import Loading from 'vue-loading-overlay';
    import 'vue-loading-overlay/dist/vue-loading.css';
    import _ from 'lodash';
    import moment from 'moment'
    import {check_url_existence_mixin} from "../../../../shared/mixins/check-url-existence-mixin";

    const STATUS_PENDING = 1;
    const STATUS_SUCCESS = 2;
    const POLL_INTERVAL = 5000;
    const MAX_POLLING_TIME = 36000000;
    export default {
        name: "Report",
        components: {ReportContent, PreparationPopup, Loading},
        data() {
            return {
                isPdfLoading: false,
                error: null,
                currentUrl: '',
                dataLoading: true,
                token: null,
                websiteUrl: null,
                domain: null,
                refresh: 0,
                status: STATUS_PENDING,
                pollInterval: 0,
                settingsLoaded: false,
                report: {
                    website: '',
                    desktopScreenshot: '',
                    mobileScreenshot: '',
                    overAllScore: 0,
                    issuesFound: 0,
                    auditDate: null,
                    updatedTime: '',
                    desktopAnalysis: {
                        loading: true,
                        largeContentfulPaint: {
                            score: null,
                            rate: null
                        },
                        cumulativeLayoutShift: {
                            score: null,
                            rate: null
                        },
                    },
                    mobileAnalysis: {
                        loading: true,
                        largeContentfulPaint: {
                            score: null,
                            rate: null
                        },
                        cumulativeLayoutShift: {
                            score: null,
                            rate: null
                        },
                    },
                    titleTag: {
                        value: null,
                        rate: null
                    },
                    descriptionTag: {
                        value: null,
                        rate: null
                    },
                    imagesCount: null,

                    wordsCount:  {
                        value: null,
                        rate: null
					},

                    altImagesCount: {
                        value: null,
                        rate: null
                    },
                    topWords: null,

                    properlySizedImages: {
                        rate: null
                    },
                    offscreenImages: {
                        rate: null
                    },
                    efficientlyEncodeImages: {
                        rate: null
                    },

                    content: {
                        loading: true
                    },
                    indexing: {
                        loading: true,
                        robotTxt: {
                            rate: null,
                            message: null
                        },
                        sitemap: {
                            rate: null,
                            message: null
                        },

                    },
                    codeAnalysis: {
                        loading: true,
                        deprecatedTags: {
                            value: null,
                            rate: null,
                        },
                        pageSize: {
                            value: null,
                            rate: null,
                        },
                        textHtmlRatio: {
                            value: null,
                            rate: null,
                        },
                        GzipCompression: {
                            value: null,
                            rate: null,
                        },
                        inlineCss: {
                            value: null,
                            rate: null,
                        },
                        internalCss: {
                            value: null,
                            rate: null,
                        },
                        microData: {
                            rate: null,
                        },
                        IpCanonicalization: {
                            rate: null,
                        }
                    },
                    pageLinkAnalysis: {
                        loading: true,
                        internalLinks: {
                            count: 0,
                            message: null
                        },
                        externalLinks: {
                            count: 0,
                            message: null
                        },
                        SEOFriendly: {
                            rate: null,
                            message: null
                        }

                    }
                },
                reportSettings: {
                    view_report: VIEW_REPORT.FULL_REPORT,
                    redirect_after_downlaod: 0,
                    redirect_afterdownload_url: '',

                    index_response_message: '',
                    score_response_message: '',
                    redirect_url: '',
                    redirect_url_time: 10,
                    favicon_path: '',
                    custom_font_radio: true,
                    custom_font: FONT_NAMES.OPEN_SANS,
                    recommendation_sheet_url: '',

                    custom_language: 0,
                    view_analytics: 1,
                    analytics_script: '',

                    show_powered_by: 1,

                    logo_path: '',
                    company_name: '',
                    company_email: '',
                    company_phone: '',

                    view_options: {
                        desktop: true,
                        mobile: true,
                        content: true,
                        titleTag: true,
                        descriptionTag: true,
                        imageAnalysis: true,
                        copyAnalysis: true,
                        pageLinkAnalysis: true,
                        indexing: true,
                        robotTxt: true,
                        siteMap: true,
                        codeAnalysis: true,
                    },
                    recommendations: {},
                    labelNames: {},
                    shortRecommendations: {},

                },
				isPdf: false
            }
        },
        mixins: [check_url_existence_mixin],

        created() {
            this.currentUrl = window.location.href;

            this.isPdf = this.$route.query.pdf;

            this.$store.dispatch('landing/getDomain', {url: this.currentUrl})
                .then((response) => {
                    this.domain = response.domain;

                    this.getSettings();

                    this.token = this.$route.query.token;
                    this.websiteUrl = this.$route.query.website_url;

                    let refresh = this.$route.query.refresh;
                    let lead_token = this.$route.query.lead_token;
                    let oldReportUrlWebsite = this.$route.query.domain;

                    if (oldReportUrlWebsite) {
                        oldReportUrlWebsite = this.correctUrl(oldReportUrlWebsite);

                        this.getOldReportToken(oldReportUrlWebsite).then((response) => {

                            let query = Object.assign({}, this.$route.query);

                            query.token = response.token;

                            delete query.domain;
                            delete query.websiteUrl;

                            this.$router.replace({query});
                            this.token = response.token;

                            this.setInterval();
                        })
                    } else if (!this.token && lead_token) {
                        this.getReportToken(lead_token).then((response) => {

                            if (!(this.websiteUrl.startsWith("http:") || this.websiteUrl.startsWith("https:"))) {
                                this.websiteUrl = "http://" + this.websiteUrl;
                            }

                            let query = Object.assign({}, this.$route.query);

                            delete query.lead_token;

                            query.token = response.token;

                            this.$router.replace({query});
                            this.token = response.token;

                            this.setInterval();
                        });
                    } else {
                        this.fetchData(refresh);

                        this.setInterval()
                    }
                })
                .catch(() => {
                    window.location.href = 'https://' + process.env.VUE_APP_DOMAIN;
                });
        },

        methods: {
            setInterval() {
                if (this.status !== STATUS_SUCCESS) {
                    this.pollInterval = setInterval(() => this.fetchData(), POLL_INTERVAL);
                    setTimeout(() => {
                        clearInterval(this.pollInterval)
                    }, MAX_POLLING_TIME);
                }
            },
            fetchData(refresh = 0) {

                let sendData = {
                    token: this.token,
                    refresh: refresh,
                };
                if (this.websiteUrl) {
                    sendData.websiteUrl = this.websiteUrl;
				}

                this.$store.dispatch('report/getReport', sendData)
                    .then((response) => {
                        if (response.status === STATUS_SUCCESS) {
                            clearInterval(this.pollInterval)
                        }

                        if (this.websiteUrl) {

                            let query = Object.assign({}, this.$route.query);

                            query.token = response.token;

                            this.$router.replace({query});

                            this.token = response.token;
                            this.websiteUrl = null;
                        }

                        this.status = response.status;
                        this.setReportData(response.report);

                    }).catch((err) => {

                    clearInterval(this.pollInterval);
                    this.dataLoading = false;
                    this.error = 'Error: Report generation failed';
                    this.showErrorNotifications(err);

                })
            },
            getSettings() {
                this.$store.dispatch('report/getSettings', this.domain)
                    .then((response) => {
                        if (response.report_manager_settings) {
                            let options = response.report_manager_settings.view_options;

                            let newOptions = {
                                ...this.reportSettings.view_options,
                                ...JSON.parse(options)
                            };

                            this.reportSettings = {
                                ...this.reportSettings,
                                ...response.report_manager_settings,
                            };

                            this.reportSettings.view_options = newOptions;

                            if (this.reportSettings.custom_language) {
                                this.reportSettings.recommendations = response.user_recommendations;
                                this.reportSettings.labelNames = this.reportSettings.shortRecommendations = {};
                                if (response.user_short_recommendations) {
                                    let escaped = response.user_short_recommendations.label_names.replace(/\n/g, "\\n").replace(/\r/g, "\\r").replace(/\t/g, "\\t");
									this.reportSettings.labelNames = JSON.parse(escaped);

                                    let escapedShort = response.user_short_recommendations.short_recommendations.replace(/\n/g, "\\n").replace(/\r/g, "\\r").replace(/\t/g, "\\t");

                                    this.reportSettings.shortRecommendations = JSON.parse(escapedShort);
								}
							}
                            this.settingsLoaded = true;
                            this.handleIcon();
                            if (this.status ===  STATUS_SUCCESS && this.reportSettings.view_report === VIEW_REPORT.FIRST_PAGE_ONLY) {
                                this.dataLoading = false;
                                this.checkRedirection();
							}
                        }
                    }).catch((err) => {

                    this.showErrorNotifications(err);

                })
            },

            handleIcon() {
                // accessing the link tag
                const favicon = document.getElementById("favicon");
                favicon.href = this.reportSettings.favicon_path;
            },
            refreshReport() {
                this.$router
                    .push({query: {...this.$route.query, refresh: '1'}})
                    .catch(() => {
                    });
                window.location.reload()
            },
            setReportData(data) {
                this.report.updatedTime = moment.utc(data.updatedTime).local().format('LLL');
                this.report.website = data.domainName;

                if (data.pageSpeedDesktop) {
                    this.report.desktopAnalysis.loading = false;
                    this.report.desktopScreenshot = data.pageSpeedDesktop.desktop_screenshot;

                    this.report.desktopAnalysis.cumulativeLayoutShift.score = data.pageSpeedDesktop.cumulative_layout_shift;
                    this.report.desktopAnalysis.cumulativeLayoutShift.rate = this.report.desktopAnalysis.cumulativeLayoutShift.score < 0.1 ? RATE.GOOD : RATE.BAD;
                    this.report.desktopAnalysis.cumulativeLayoutShift.message = SHORT.CumulativeLayoutShift[this.report.desktopAnalysis.cumulativeLayoutShift.rate];

                    this.report.desktopAnalysis.largeContentfulPaint.score = data.pageSpeedDesktop.large_contentful_paint ? data.pageSpeedDesktop.large_contentful_paint / 1000 : null;
                    this.report.desktopAnalysis.largeContentfulPaint.rate = this.report.desktopAnalysis.largeContentfulPaint.score < 2.5 ? RATE.GOOD : RATE.BAD;
                    this.report.desktopAnalysis.largeContentfulPaint.message = SHORT.LargeContentfulPaint[this.report.desktopAnalysis.largeContentfulPaint.rate];

                    this.report.properlySizedImages.rate = data.pageSpeedDesktop.properly_sized_images_score != null ? RATE.GOOD : RATE.BAD;
                    this.report.properlySizedImages.message = SHORT.ImageAnalyses[this.report.properlySizedImages.rate];

                    this.report.offscreenImages.rate = data.pageSpeedDesktop.offscreen_images != null ? RATE.GOOD : RATE.BAD;
                    this.report.offscreenImages.message = SHORT.DeferOffscreenImages[this.report.offscreenImages.rate];

                    this.report.efficientlyEncodeImages.rate = data.pageSpeedDesktop.efficiently_encode_images != null ? RATE.GOOD : RATE.BAD;
                    this.report.efficientlyEncodeImages.message = SHORT.EfficientlyEncodeImages[this.report.efficientlyEncodeImages.rate];


                }
                if (data.pageSpeedMobile) {
                    this.report.mobileAnalysis.loading = false;
                    this.report.mobileScreenshot = data.pageSpeedMobile.mobile_screenshot;

                    this.report.mobileAnalysis.cumulativeLayoutShift.score = data.pageSpeedMobile.cumulative_layout_shift;
                    this.report.mobileAnalysis.cumulativeLayoutShift.rate = this.report.mobileAnalysis.cumulativeLayoutShift.score < 0.1 ? RATE.GOOD : RATE.BAD;
					this.report.mobileAnalysis.cumulativeLayoutShift.message = SHORT.CumulativeLayoutShift[this.report.mobileAnalysis.cumulativeLayoutShift.rate];

                    this.report.mobileAnalysis.largeContentfulPaint.score = data.pageSpeedMobile.large_contentful_paint ? data.pageSpeedMobile.large_contentful_paint / 1000 : null;
                    this.report.mobileAnalysis.largeContentfulPaint.rate = this.report.mobileAnalysis.largeContentfulPaint.score < 2.5 ? RATE.GOOD : RATE.BAD;
                    this.report.mobileAnalysis.largeContentfulPaint.message = SHORT.LargeContentfulPaint[this.report.mobileAnalysis.largeContentfulPaint.rate];

                }
                if (data.links) {
                    this.report.pageLinkAnalysis.loading = false;

                    this.report.pageLinkAnalysis.externalLinks.count = data.links.external_link_count;
                    this.report.pageLinkAnalysis.externalLinks.rate = this.report.pageLinkAnalysis.externalLinks.count > 0 ? RATE.GOOD : RATE.BAD;
                    this.report.pageLinkAnalysis.externalLinks.message = SHORT.ExternalLinks[RATE.GOOD].replace(':100', data.links.internal_link_count);

                    this.report.pageLinkAnalysis.internalLinks.count = data.links.internal_link_count;
                    this.report.pageLinkAnalysis.internalLinks.rate = this.report.pageLinkAnalysis.internalLinks.count > 0 ? RATE.GOOD : RATE.BAD;
                    this.report.pageLinkAnalysis.internalLinks.message = SHORT.InternalLinks[RATE.GOOD].replace(':100', data.links.internal_link_count);

                    let notSEOFriendlyLinksCount = data.links.not_seo_friendly_link_count;

                    this.report.pageLinkAnalysis.SEOFriendly.rate = !notSEOFriendlyLinksCount ? RATE.GOOD : RATE.BAD;
                    this.report.pageLinkAnalysis.SEOFriendly.message = SHORT.SEOFriendly[this.report.pageLinkAnalysis.SEOFriendly.rate];
                }

                if (data.onPageSEO) {
                    this.report.indexing.loading = false;
                    this.report.codeAnalysis.loading = false;
                    this.report.content.loading = false;
                    this.report.issuesFound = data.onPageSEO.warning_count;

                    this.report.titleTag.value = data.onPageSEO.title;
                    this.report.titleTag.rate = data.onPageSEO.title.length === 0 ? RATE.BAD : (data.onPageSEO.title.length < 70 ? RATE.GOOD : RATE.AVERAGE);
                    this.report.titleTag.message = SHORT.TitleTag[this.report.titleTag.rate];

                    this.report.descriptionTag.value = data.onPageSEO.metadescription;
                    this.report.descriptionTag.rate = data.onPageSEO.metadescription.length === 0 ? RATE.BAD : (data.onPageSEO.metadescription.length < 160 ? RATE.GOOD : RATE.AVERAGE);
                    this.report.descriptionTag.message = SHORT.DescriptionTag[this.report.descriptionTag.rate];

                    this.report.imagesCount = data.onPageSEO.total_images;
                    this.report.wordsCount.value = data.onPageSEO.total_words;
                    this.report.wordsCount.rate = this.report.wordsCount.value > 400 ? RATE.GOOD : RATE.BAD;
                    this.report.wordsCount.message = SHORT.TotalWordsCount[this.report.wordsCount.rate].replace(':100', this.report.wordsCount.value);

                    this.report.altImagesCount.value = data.onPageSEO.image_without_alt_count;
                    this.report.altImagesCount.rate = this.report.altImagesCount.value > 0 ? RATE.BAD : RATE.GOOD;
                    this.report.altImagesCount.message = SHORT.ImageAltText[this.report.altImagesCount.rate].replace(':100', this.report.altImagesCount.value);

                    let keywordOnePhrase = JSON.parse(data.onPageSEO.keyword_one_phrase);

                    let i = 0;
                    this.report.topWords = [];

                    for (const word in keywordOnePhrase) {
                        this.report.topWords[i] = {word: word, count: keywordOnePhrase[word]};
                        i++;
                    }

                    this.report.indexing.robotTxt.rate = data.onPageSEO.robots_txt_exist == 1 ? RATE.GOOD : RATE.BAD;
                    this.report.indexing.robotTxt.message = SHORT.RobotTxt[this.report.indexing.robotTxt.rate];

                    this.report.indexing.sitemap.rate = data.onPageSEO.sitemap_exist == 1 ? RATE.GOOD : RATE.BAD;
                    this.report.indexing.sitemap.message = SHORT.SiteMap[this.report.indexing.sitemap.rate];

                    let deprecatedTags = JSON.parse(data.onPageSEO.depreciated_html_tag);

                    this.report.codeAnalysis.deprecatedTags.value = Object.keys(deprecatedTags).filter(k => Boolean(deprecatedTags[k])).length;
                    this.report.codeAnalysis.deprecatedTags.rate = this.report.codeAnalysis.deprecatedTags.value === 0 ? RATE.GOOD : RATE.BAD;
                    this.report.codeAnalysis.deprecatedTags.message = SHORT.DeprecatedHTMLTag[this.report.codeAnalysis.deprecatedTags.rate].replace(':100', this.report.codeAnalysis.deprecatedTags.value);

                    this.report.codeAnalysis.pageSize.value = data.onPageSEO.total_page_size_general;
                    this.report.codeAnalysis.pageSize.rate = data.onPageSEO.total_page_size_general > 100 ? RATE.BAD : RATE.GOOD;
                    this.report.codeAnalysis.pageSize.message = SHORT.HtmlPageSize[this.report.codeAnalysis.pageSize.rate].replace(':100', this.report.codeAnalysis.pageSize.value);

                    this.report.codeAnalysis.GzipCompression.value = data.onPageSEO.page_size_gzip;
                    this.report.codeAnalysis.GzipCompression.rate = data.onPageSEO.page_size_gzip === 0 || data.onPageSEO.page_size_gzip > 32 ? RATE.BAD : RATE.GOOD;
                    this.report.codeAnalysis.GzipCompression.message = SHORT.GzipCompression[this.report.codeAnalysis.GzipCompression.rate].replace(':100', this.report.codeAnalysis.GzipCompression.value);

                    this.report.codeAnalysis.textHtmlRatio.rate = data.onPageSEO.text_to_html_ratio < 20 ? RATE.BAD : RATE.GOOD;
                    this.report.codeAnalysis.textHtmlRatio.message = SHORT.TextHtmlRatio[this.report.codeAnalysis.textHtmlRatio.rate];

                    let inlineCss = JSON.parse(data.onPageSEO.inline_css);

                    this.report.codeAnalysis.inlineCss.value = _.size(inlineCss);
                    this.report.codeAnalysis.inlineCss.rate = this.report.codeAnalysis.inlineCss.value === 0 ? RATE.GOOD : RATE.BAD;
                    this.report.codeAnalysis.inlineCss.message = SHORT.InlineCSS[this.report.codeAnalysis.inlineCss.rate].replace(':100', this.report.codeAnalysis.inlineCss.value);

                    let internalCss = JSON.parse(data.onPageSEO.internal_css);

                    this.report.codeAnalysis.internalCss.value = _.size(internalCss);
                    this.report.codeAnalysis.internalCss.rate = this.report.codeAnalysis.internalCss.value === 0 ? RATE.GOOD : RATE.BAD;
                    this.report.codeAnalysis.internalCss.message = SHORT.InternalCSS[this.report.codeAnalysis.internalCss.rate].replace(':100', this.report.codeAnalysis.internalCss.value);


                    let microData = JSON.parse(data.onPageSEO.micro_data_schema_list);

                    this.report.codeAnalysis.microData.rate = _.size(microData) > 0 ? RATE.GOOD : RATE.BAD;
                    this.report.codeAnalysis.microData.message = SHORT.MicroDataSchemaTest[this.report.codeAnalysis.microData.rate];

                    this.report.codeAnalysis.IpCanonicalization.rate = data.onPageSEO.is_ip_canonical == '0' ? RATE.BAD : RATE.GOOD;
                    this.report.codeAnalysis.IpCanonicalization.message = SHORT.IPCanonicalizationTest[this.report.codeAnalysis.IpCanonicalization.rate];

                }
                if (data.overallScore && data.onPageSEO) {
                    this.report.overAllScore = data.overallScore;
                    this.dataLoading = false;
                }

                if (this.settingsLoaded && this.reportSettings.view_report === VIEW_REPORT.FIRST_PAGE_ONLY && !this.dataLoading) {
                    clearInterval(this.pollInterval);
                    this.checkRedirection();
                }
            },
            checkRedirection(afterDownload = false) {
                if (this.reportSettings.view_report === VIEW_REPORT.FIRST_PAGE_ONLY && this.reportSettings.redirect_url) {
                    setTimeout(() => {
                        let url = this.reportSettings.redirect_url;
                        if (!(url.match(/^http?:\/\//i) || url.match(/^https?:\/\//i))) {
                            url = 'http://' + url;
                        }
                        window.location.href = url;
                    }, this.reportSettings.redirect_url_time * 1000);

                }
                if (afterDownload && this.reportSettings.view_report === VIEW_REPORT.FULL_REPORT && this.reportSettings.redirect_after_downlaod && this.reportSettings.redirect_afterdownload_url) {
                    setTimeout(() => {
                        let url = this.reportSettings.redirect_afterdownload_url;
                        if (!(url.match(/^http?:\/\//i) || url.match(/^https?:\/\//i))) {
                            url = 'http://' + url;
                        }
                        window.location.href = url;
                    }, 2000);
                }
            },
            handleSuccess() {
                this.showNotifications({message: 'Copied to clipboard', type: 'success'});
            },

            handleError() {
                this.showNotifications({message: 'Can not copy', type: 'danger'});
            },
            generatePDF() {
                let sendData = {
                    token: this.token,
                    email: this.reportSettings.company_email,
                    phone: this.reportSettings.company_phone,
                    domainId: this.domain,
                    refresh: 0
                };

                if (this.report.website) {
                    sendData.websiteUrl = this.report.website;
                }

                this.isPdfLoading = true;
                this.$refs.pdfDropdown.hide(true);

                this.$store.dispatch('report/generatePDF', sendData)
                    .then((response) => {
                        var blob = new Blob([response]);
                        var link = document.createElement('a');
                        link.href = window.URL.createObjectURL(blob);

                        link.download = 'ReportPDF.pdf';
                        if (this.report.website) {
                            const url = new URL(this.report.website);
                            link.download = url.hostname + ".pdf";
                        }

                        link.click();
                        this.isPdfLoading = false;
                        this.checkRedirection(true);

                    })
                    .catch(() => {
                        this.showNotifications({message: 'Unable to generate PDF', type: 'danger'})
                        this.isPdfLoading = false;
                    })
            },

            getReportToken(lead_token) {
                return new Promise((resolve, reject) => {
                    let sendData = {
                        token: lead_token,
                        website_url: this.websiteUrl,
                        domain_id: this.domain
                    };
                    this.$store.dispatch('report/getToken', sendData)
                        .then((response) => {
                            resolve(response);

                        }).catch((err) => {
                        this.showErrorNotifications(err);
                        reject(err)
                    })

                });
            },

            getOldReportToken(website) {

                return new Promise((resolve, reject) => {
                    let sendData = {
                        websiteUrl: website,
                        domainId: this.domain
                    };
                    this.$store.dispatch('report/getOldReportToken', sendData)
                        .then((response) => {
                            resolve(response);

                        }).catch((err) => {
                        this.showErrorNotifications(err);
                        reject(err)
                    })

                });
            }
        }
    }
</script>

<style lang="scss">
	@import "../../../../shared/assets/styles/blocks/generate-reports";
	@import "../../../../shared/assets/styles/blocks/setup-audit-form";

</style>
<style scoped>
	.dropdown-export input:disabled {
		background-color: white;
	}

</style>

import axios from "axios";
import {REPORT} from "../links";

export const check_url_existence_mixin = {

    methods: {
        checkUrl(website_url) {
            return new Promise((resolve, reject) => {

                let url = REPORT.CHECK_URL + '?website_url=' + website_url;
                return axios.get(url).then((response) => {
                    resolve(response.data.exist);
                }).catch((err) => {
                    this.showErrorNotifications(err);
                    reject(err)
                });
            });

        },
        getHostname(url) {
            // use URL constructor and return hostname
            return new URL(url).hostname;
        },
        correctUrl(websiteUrl) {

            if (websiteUrl && !(websiteUrl.startsWith("http://") || websiteUrl.startsWith("https://"))) {
                const secureUrl = "https://" + websiteUrl;

                websiteUrl = this.checkUrl(secureUrl) ? "https://" + websiteUrl : "http://" + websiteUrl;

            } else if (websiteUrl.startsWith("http://")) {
                const secureUrl = "https://" + this.getHostname(websiteUrl);

                websiteUrl = this.checkUrl(secureUrl) ? websiteUrl.replace("http://", 'https://') : websiteUrl;
            }

            return websiteUrl
        }

    }
}

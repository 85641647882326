<template>
	<div class="sf-cnt-footer">
		<div class="sf-cnt-footer__contacts">{{phone}}<span v-if="phone && email"> </span>{{email}}</div>
		<div class="sf-cnt-footer__page" ><span class='pdfcrowd-page-number'></span></div>
	</div>
</template>

<script>
    export default {
        name: "FooterPdf.vue",
		data() {
            return {
                email: 'companie@email.com',
				phone: '+1 987-654-321'
			}
		},
        mounted() {
            this.phone = this.$route.query.phone;
            this.email = this.$route.query.email;
        }
    }
</script>

<style scoped>
	.sf-cnt-footer {
		font-family:  Roboto, sans-serif, Helvetica, Arial, sans-serif;
		background-color: white;
		padding-right: 20px;
		display: flex;
		justify-content: space-between;
		height: 70px;
		align-items: flex-start;
	}
	.sf-cnt-footer__page {
		font-size: 13px;
		line-height: 15px;
		text-align: right;
		color: #15394F;
	}

	.sf-cnt-footer__contacts {
		margin-left: 50px;
		display: flex;
		align-items: center;
	}
	.sf-cnt-footer__contacts {
		font-size: 11px;
		line-height: 13px;
		text-align: right;
		color: #9BA0A5;
	}

	.sf-cnt-footer__contacts span {
		display: block;
		width: 2px;
		height: 2px;
		background: #C4C4C4;
		margin: 0 9px;
	}

</style>

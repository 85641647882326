<template>
	<div class="loading-lines">
		<div class="loading-line"></div>
		<div class="loading-line report-line-2"></div>
	</div>
</template>

<script>
    export default {
        name: "Loading"
    }
</script>

<style scoped lang="scss">
	.loading-line {
		border-radius: 2px;
		height: 12px;
		max-width: 250px;
		margin: 0 auto 8px;
		background-image: linear-gradient(90deg, #D7DDE2 0px, #F8F9FA 200px, #D7DDE2 400px);
		background-size: 600px;
		animation: gradient 2s linear infinite;

		&:last-child {
			margin: 0 auto;
		}

		&-1 {
			width: 250px;
		}

		&-2 {
			width: 100px;
		}
	}

	.loading-lines {
		margin-bottom: 30px;
	}
</style>

<template>
	<main class="main">
		<div class="audit-loader">
			<div class="audit-loader__cnt">
				<p class="report-subtitle">One moment</p>
				<h2 class="report-title">Let us prepare Report for you...</h2>
				<div class="report-wrap">
					<div class="report">
						<div class="report-cnt">
							<div class="report-img"><img src="../../../../shared/assets/styles/images/popup-report-icon.svg" alt=""></div>
							<div class="report-lines">
								<div class="report-line"></div>
								<div class="report-line report-line-2"></div>
							</div>
							<div class="report-list">
								<div class="report-list__inner">
									<div class="report-list__items">
										<div class="report-list__item" v-for="item in blocks" :key="item">
											<div class="report-list__check">
												<div class="report-list__check-icon"></div>
											</div>
											<div class="report-list__txt">{{item}}</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</main>

</template>

<script>
    export default {
        name: "PreporationPopup",
		data() {
            return {
                blocks: [
                    'Collecting Single Page Audit Report',
                    'Collecting  Desktop Analysis',
                    'Collecting Mobile Analysis',
                    'Checking Title Tag',
                    'Checking Description Tag',
                    'Collecting Image Analysis',
                    'Collecting Copy Analysis',
                    'Collecting Top 5 Words Used on this page',
                    'Collecting Page Link Analysis',
                    'Checking Indexing',
                    'Checking Sitemap',
                    'Collecting Code Analysis',
                    'Finalizing Report Information',
				]
			}
		},
        methods: {
            reportListAnime() {
                setTimeout(function () {
                    var e, t, l, o;
                    0 < document.querySelectorAll(".report-list").length && (
                        e = document.querySelector(".report-list__items"),
							t = document.querySelectorAll(".report-list__check-icon"),
							l = e.offsetHeight, o = document.querySelectorAll(".report-list__item").length,
							e.setAttribute("style", "transition: " + .9 * o + "s linear; transform: translateY(-" + l + "px)"),
							t.forEach(function (e, t) {

                        e.setAttribute("style", "animation-duration: 0.7s; animation-delay: " + .9 * t + "s;")
                    }))
                }, 500)
            },

        },
        mounted() {
           window.addEventListener('load', this.reportListAnime);
        },

    }
</script>
